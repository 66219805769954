import Vue    from 'vue'
import tvmaga from '../mixins/tvmaga'

const TOKEN = document.getElementsByName('csrf-token')[0].getAttribute('content')
const HEADERS = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': TOKEN,
}

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#tvmaga',
    mixins: [tvmaga],
    data: {
      posts: []
    },
    async mounted() {
      const post  = await this.fetchTvMaga()
      this.posts = post.data
    },

  })
})