import axios from 'axios'
export default {
  methods: {
    goTvMaga(url){
      window.location = url
    },
    async fetchTvMaga(query = "") {
      return axios
      .get(`https://saru.co.jp/tvmaga/wp-json/wp/v2/posts${query}`, {
        responseType: 'json',
      })
    }
  }
}